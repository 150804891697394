import './App.css';
import React, { useEffect, useState } from "react";
import { useRef } from 'react';
import axios from 'axios';
import Header from "./Header";
import SessionAddDialog from "./components/SessionAddDialog";
import SessionEditDialog from "./components/SessionEditDialog";
import UserAddDialog from "./components/UserAddDialog"
import UserSessionEditDialog from "./components/UserSessionEditDialog"
import ConfirmDeleteUserDialog from "./components/ConfirmDeleteUserDialog"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { SERVERURI_PORT } from './components/constants'
import {REACT_APP_BACKEND}  from './components/constants'

import { CookiesProvider, useCookies } from 'react-cookie'
import ConfirmDialog from './components/ConfirmDialog'
//import CookieConsent from "react-cookie-consent";



var TEAMROWNAME = 'Training Row-'
var OPENROWNAME = '1x, self org'
var IncludeSaturdayRecRow = false;
var SunTEAMTime = '8:00am';
var SundayDescription = 'Training Row';
var SaturdayTeamDescription = 'Sat desc';
var SatTEAMTTIME = '7:00am';
var SaturdayRecRowStart = '8:30am';
var SaturdayRecDescription = 'Rec Row';
var MaxSeats= 100;

var showToday = true;
var CALMAXSIZE = 15;
const CYCADMIN = "cycrowing@gmail.com";
const CYCFIRSTNAME = "cycadmin";
const CYCLASTNAME = "admin"

//const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
var descriptions = [TEAMROWNAME, OPENROWNAME, OPENROWNAME, OPENROWNAME, OPENROWNAME, OPENROWNAME, TEAMROWNAME]


var startTimes = [SunTEAMTime, '-', '-', '-', '-', '-', SatTEAMTTIME];



var calendarJO = [];


function App() {

  const [peopleSignedUp, setPeopleSignedUp] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [userJO, setUserJO] = useState({ FirstName: "", LastName: "", Email: "" })
  const [cookies, setCookie, removeCookie] = useCookies(['rower'])
  const [data, setData] = useState([]);
  const [properties, setProperties] = useState([]);
  let peopleCount= useRef(0);

  function setUser(jo) {
    //var newUser = { FirstName: jo.FirstName, LastName: jo.LastName, Email: jo.Email }
    setUserJO(jo);
    setCookie('rower', jo,
      {
        path: '/',
        expires: new Date(Date.now() + 315360000000)
      })
  }

  function logout() {
    removeCookie('rower')
    setUserJO({ FirstName: "", LastName: "", Email: "" })
  }


  function setupProperties(p) {

  
    if (p === null || p.length == 0) { return };
    var prop = p
    TEAMROWNAME = prop.SaturdayDescription;
    OPENROWNAME = prop.OpenRowDescription;
    CALMAXSIZE = prop.NumberOfDaysToShow

    IncludeSaturdayRecRow = prop.IncludeSaturdayRecRow;
    SunTEAMTime = prop.SundayDefaultStart;
    SundayDescription = prop.SundayDescription;

    SatTEAMTTIME = prop.SaturdayDefaultStart;
    SaturdayTeamDescription = prop.SaturdayTeamDescription;
    SaturdayRecRowStart = prop.SaturdayRecRowStart;
    SaturdayRecDescription = prop.SaturdayRecDescription;
    MaxSeats = prop.MaxSeats;

    descriptions = [SundayDescription, OPENROWNAME, OPENROWNAME, OPENROWNAME, OPENROWNAME, OPENROWNAME, TEAMROWNAME]
    startTimes = [SunTEAMTime, '-', '-', '-', '-', '-', SatTEAMTTIME];
    //console.log('here')
  }

  function updateSettings(jo) {
    if (jo.IncludeSaturdayRecRow != null) jo.IncludeSaturdayRecRow = jo.IncludeSaturdayRecRow.toLowerCase();
    updatePutProperties(jo);
   
  }
  function addRowSession(jo) {

    if (jo.Date != null && jo.Date != '') {
      var sessions = getRemoteSessionsByDate(jo.Date);
      sessions.sort((a, b) =>
        (a.Order > b.Order) ? 1 : ((b.Order > a.Order) ? -1 : 0))

      var nextOrder = (sessions[sessions.length - 1]).Order;
      var newSession = { Date: jo.Date, Time: jo.Time, Description: jo.Description, Order: nextOrder + 1 };
      postNewRowSession(newSession)
    }
  }

  //if overriding DEFAULT post directly using same ORDER (not incremented)
  const addOrEditRowSession = async (aRowingSession) => {
    // alert(JSON.stringify(aRowingSession))

    if (aRowingSession._id === "") delete aRowingSession._id

    if (aRowingSession._id == null) {
      postNewRowSession(aRowingSession);  //Post as is including ORDER #
    } else {
      updatePutRowSession(aRowingSession)
    }


  }

  const makeCalendar = async () => {
    calendarJO = [];

    if (calendarJO.length > CALMAXSIZE) return;
    var today = new Date();
    var hour = today.getHours();
    var nextDay = new Date(today.getTime());

    var isAfternoon = (hour >= 22);
    // if its afternoon and date is today and do not show today advance by a day
    if ( !showToday)
      nextDay.setDate(nextDay.getDate() + 1);

    for (let i = 0; i < CALMAXSIZE; i++) {

      calendarJO.push({ "Date": nextDay.toDateString(), "Sessions": [] })
      nextDay.setDate(nextDay.getDate() + 1);
    }

    setCalendar(calendarJO);

  }




  //*********************Properties************ */
  const fetchProperties = async () => {
   
    //console.log(window.location.hostname)  
     
    const p = await axios.get(SERVERURI_PORT + "/properties")
    var { properties } = await axios.get(SERVERURI_PORT + "/properties")
      .catch((err) => { console.log(err) });
    properties = p.data[0];
    setupProperties(p.data[0]);
    setProperties(properties);
    makeCalendar()
    //console.log('here fetchprops')
  };
  const updatePutProperties = async (jo) => {
    const p = await axios.get(SERVERURI_PORT + "/properties")
    var { data } =await axios.put(SERVERURI_PORT + "/properties/", jo).catch((err) => console.log(err));
    setupProperties(data);
    setProperties(data);
    makeCalendar()
  };


  ////********************** Row Sessions ************** */
  const fetchRowSessions = async () => {
    const { data } = await axios(SERVERURI_PORT + "/rowsessions").catch((err) => { console.log(err) });
    setData(data);
    fetchPeopleSignedUp(); //********************* */

  };
  const postNewRowSession = async (aRowingSession) => {
    await axios.post(SERVERURI_PORT + "/rowsessions/", aRowingSession)
      .catch(
        (err) => console.debug(err));
    fetchRowSessions()
  };
  const deleteRowSession = async (aRowingSession) => {
    await axios.delete(SERVERURI_PORT + "/rowsessions/" + aRowingSession._id)
      .catch(
        (err) => console.debug(err));
    fetchRowSessions()
  };
  const updatePutRowSession = async (aRowingSession) => {
    await axios.put(SERVERURI_PORT + "/rowsessions/", aRowingSession)
      .catch(
        (err) => console.log(err));
    fetchRowSessions()
  };

  ////*************   ******** Signups************** */
  /**Add new post */
  const addPeopleSignedUp = async (aFormSignup) => {
    var aSignup;
    if (aFormSignup.Comment !== null && aFormSignup.Comment !== "")
      aSignup = {
        Date: aFormSignup.Date,
        Order: aFormSignup.Order,
        FirstName: cookies.rower.FirstName,
        LastName: cookies.rower.LastName,
        Email: cookies.rower.Email,
        Quantity: aFormSignup.Quantity,
        Comment: aFormSignup.Comment
      }
    else
      aSignup = {
        Date: aFormSignup.Date,
        Order: aFormSignup.Order,
        FirstName: cookies.rower.FirstName,
        LastName: cookies.rower.LastName,
        Email: cookies.rower.Email,
        Quantity: aFormSignup.Quantity,

      }
    await axios.post(SERVERURI_PORT + "/peoplesignedup", aSignup)
      .catch(
        (err) => console.log(err));
    fetchPeopleSignedUp()
  };

  /**Update */
  const updatePersonSignedUp = async (aSignup) => {

    await axios.put(SERVERURI_PORT + "/peoplesignedup", aSignup)
      .catch(
        (err) => console.log(err));
    fetchPeopleSignedUp()
  };

  /**Delete */
  const deletePeopleSignedUp = async (aRowingSession) => {
    await axios.delete(SERVERURI_PORT + "/peoplesignedup/" + aRowingSession._id).catch((err) => console.log(err));
    fetchPeopleSignedUp()
  };
  /** Get */
  const fetchPeopleSignedUp = async () => {

    const { data } = await axios.get(SERVERURI_PORT + "/peoplesignedup")
      .catch((err) => { console.log(err) });

    setPeopleSignedUp(data);
    console.debug('here fetchPeoplesignup')
  };



  useEffect(() => {
    fetchProperties();
  }, []);


  useEffect(() => {
    fetchRowSessions();
  }, []);




  function getPeopleForASession(aRowSession) {
    var peopleForSession = [];
    if (peopleSignedUp === null) return peopleForSession;
    const aDate = normalizeDate(aRowSession.Date)
    peopleForSession = peopleSignedUp.filter(r => (
      r.Order === aRowSession.Order &&
      normalizeDate(r.Date).getTime() === aDate.getTime()
    ))

    return peopleForSession;
  }


  function isSignedUpForASession(aRowSession) {
    var peeps = getPeopleForASession(aRowSession)
    const me = cookies.rower;

    var getMe = peeps.filter(r => (r.Email.toLowerCase() === me.Email.toLowerCase()))
    return (getMe.length > 0)

  }

  function countPeopleForASession(aRowingSession) {
    var peeps = getPeopleForASession(aRowingSession)
    var count = 0
    peeps.forEach(e => (count = count + e.Quantity))
     peopleCount.current=count;
    return count;
  }

  function normalizeDate(aDateString) {
    if (aDateString == null) return new Date();
    var d = new Date(aDateString.replace('.000Z', ''))
    d.setHours(0, 0, 0, 0)
    return d
  }

  function isSameDate(aDateString, bDate) {
    var bool = false;
    var aDateS = aDateString.replace('.000Z', '');
    var aDate = new Date(aDateS);
    //var bDate = new Date(bDateString);
    //bool= (aDate.getDate()==bDate.getDate());
    bool = ((aDate.getDate() === bDate.getDate()) &&
      (aDate.getMonth() === bDate.getMonth()) &&
      (aDate.getFullYear() === bDate.getFullYear()));

    return bool;
  }                                     //Defaults  Description = "Training Row", Time="7:00am"
  function isSessionAnotinB(aSession, bArray) {
    var set = bArray.filter(r => r.Order === aSession.Order)
    return set.length === 0;
  }
  function isSessionAinB(aSession, bArray) {
    var set = bArray.filter(r => r.Order === aSession.Order)
    return set.length !== 0;
  }

  function getDefaultSessionsForDate(date) {
    var s = [];
    var def = {
      "Date": new Date(date).toDateString(), "Order": 1, "Time": startTimes[date.getDay()],
      "Description": descriptions[date.getDay()]
    };
    s.push(def);
    // ADD Rec Row on Saturday in future
    if (date.getDay() === 6 && IncludeSaturdayRecRow /*saturday */) {
      def = {
        "Date": new Date(date).toDateString(), "Order": 2, "Time": SaturdayRecRowStart,
        "Description": SaturdayRecDescription
      };
      s.push(def)
    }

    return s;

  }
  function getSessionsForDateWithOverrides(date, overrides) {

    var defaults = getDefaultSessionsForDate(date);
    var finalSessions = defaults.filter(rs => isSessionAnotinB(rs, overrides)); //all defaults without Overrides
    var overridesWithoutDefaults = overrides.filter(rs => isSessionAinB(rs, defaults)) //all overrides without default
    var overridesWithDefaults = overrides.filter(rs => isSessionAnotinB(rs, defaults));  //all overrides without defaults
    if (overridesWithDefaults.length > 0) finalSessions = finalSessions.concat(overridesWithDefaults)
    if (overridesWithoutDefaults.length > 0) finalSessions = finalSessions.concat(overridesWithoutDefaults);
    return finalSessions;


  }

  function getRemoteSessionsByDate(dateString) {
    var date = new Date(dateString);
    if (data === null) return [];
    var sessionsJO = data.filter(rs => isSameDate(rs.Date, date))
    var compositeSessions = getSessionsForDateWithOverrides(date, sessionsJO);

    return compositeSessions;
  }

  function getSessionsForDate(dateString) {

    var date = new Date(dateString);


    var compositeSessions = getRemoteSessionsByDate(date);
    compositeSessions = compositeSessions.sort((a, b) => {
      if (a.Order < b.Order) {
        return -1;
      }
    });

    if (peopleSignedUp == null) return;

    return (

      <table >
        {compositeSessions.map((value, key) => {
          return (
            <tbody>
              <tr>
                <td className='td3'>
                  
                  {value.Time + " "}{value.Description}   {" [" + countPeopleForASession(value ) + (MaxSeats===1000 ? "": "/" + MaxSeats )  +"] "}
                  {!isAdmin()&& peopleCount.current < MaxSeats && cookies.rower != null && !isSignedUpForASession(value) ?
                    <UserAddDialog rowingSession={value} person={cookies.rower} availableSeats={MaxSeats-Number.parseInt(peopleCount.current)} acceptCallBack={addPeopleSignedUp}> </UserAddDialog>
                    : ''}

                  {isAdmin() && (value._id !== null && value._id !== '') ?
                    <ConfirmDialog acceptCallBack={deleteRowSession} rowingSession={value} ></ConfirmDialog>
                    : ''}
                  {isAdmin() ?
                    <SessionEditDialog acceptCallBack={addOrEditRowSession} rowingSession={value} ></SessionEditDialog>
                    : ''}
                </td>
              </tr>
              <tr key={key}>
                <td className='td2' >
                  <List dense={true} sx={{
                    dense: true,
                    position: 'relative',
                    margin: 0,
                    '& ul': { padding: 0 }
                  }}
                  >
                    {getPeopleForASession(value).map((v, k) => {
                      return (
                        <ListItem disablePadding={true} key={k} secondaryAction={
                          <div>
                            {(cookies.rower != null && (isAdmin() || (cookies.rower.Email.toLowerCase() === v.Email.toLowerCase()))) ?
                              <div>
                                <ConfirmDeleteUserDialog rowingSession={v} acceptCallBack={deletePeopleSignedUp}></ConfirmDeleteUserDialog>
                                <UserSessionEditDialog rowingSession={v} acceptCallBack={updatePersonSignedUp}></UserSessionEditDialog>
                              </div>
                              : null}


                          </div>

                        } >
                          {
                            (v.Comment !== null && v.Comment !== "") ?
                              <ListItemText
                                primary={v.Quantity !== 1 ? v.FirstName + " " + v.LastName + " (" + v.Quantity + ")" : v.FirstName + " " + v.LastName}
                                secondaryTypographyProps={{ margin: "2px", color: "lightgray", variant: "caption" }}
                                secondary={v.Comment} />
                              :
                              <ListItemText
                                primary={v.Quantity !== 1 ? v.FirstName + " " + v.LastName + " (" + v.Quantity + ")" : v.FirstName + " " + v.LastName} />
                          }

                        </ListItem>

                      );
                    })}
                  </List>

                </td>
              </tr>
            </tbody>
          );
        })}
      </table>

    );
  }
  function isAdmin() {
    return (cookies.rower != null
      && cookies.rower.Email === CYCADMIN
      && cookies.rower.FirstName === CYCFIRSTNAME
      && cookies.rower.LastName === CYCLASTNAME)
  }
  function setShowToday() {
    showToday = !showToday;
    makeCalendar()
  }



  return (
    <CookiesProvider>
      <div className="App">
      
  
        <Header setShowToday={setShowToday} isAdmin={isAdmin()} properties={properties} acceptCallBack={updateSettings} logoutParent={logout} setUserInParent={setUser} currentUser={cookies.rower} ></Header>

        <header>
          <br></br>
        </header>

        <div className="App">
          <table id='MainSign'>

            <tbody>
              {calendar.map((value, key) => {
                return (
                  <tr key={key}>

                    <td >{value.Date} {(isAdmin()) ?
                      <SessionAddDialog addOrEditRowSession={addRowSession} rowSession={value} ></SessionAddDialog>
                      : null}
                      {getSessionsForDate(value.Date)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

      </div>
    </CookiesProvider>
  );
}

export default App;
